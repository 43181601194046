import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { PageLoader } from "freespace-react-components";
import "freespace-react-components/style.css";
import "./index.css";
import ServicesContext from "./context/ServicesContext";
import AppLayout from "./pages/_layout";

const AppRoute = lazy(() => import("./routes"));
export default function Root({
  fallback,
  spaceIOService,
  userService,
  configService,
  locationService,
  freespaceService,
  featureService,
  permissionService,
}) {
  return (
    <ServicesContext.Provider
      value={{
        spaceIOService,
        userService,
        configService,
        locationService,
        freespaceService,
        featureService,
        permissionService,
      }}
    >
      <Router>
        <Routes>
          <Route
            caseSensitive
            path={"*"}
            element={
              <AppLayout>
                <Suspense fallback={fallback || <PageLoader loading={true} />}>
                  <AppRoute />
                </Suspense>
              </AppLayout>
            }
          />
        </Routes>
      </Router>
    </ServicesContext.Provider>
  );
}

